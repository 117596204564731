import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar(props) {
  const [activeClass, setactiveClass] = useState(props.data);

  return (
    <div className="m-0" style={{backgroundColor:'transparent',color:'white',zIndex:-2} }>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid m-0 p-0">
          <Link className="navbar-brand logo" to="/home">
            <img src="./assets/polarverse-logo.png" width={45} height={80}  alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto text-right  font-roboto">
              <li className="nav-item nav-font  ">
                <Link
                  to="/home"
                  onClick={() => {
                    setactiveClass("home");
                  }}
                  className={`nav-link ${
                    activeClass === "home" ? "active" : ""
                  }  `}
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item nav-font  ">
                <Link
                  to="/gaming"
                  onClick={() => {
                    setactiveClass("/gaming");
                  }}
                  className={`nav-link ${
                    activeClass === "/gaming" ? "active" : ""
                  }  `}
                  aria-current="page"
                >
                  Gaming 
                </Link>
              </li>
              <li className="nav-item nav-font  ">
                <Link
                  to="/music"
                  onClick={() => {
                    setactiveClass("/music");
                  }}
                  className={`nav-link ${
                    activeClass === "/music" ? "active" : ""
                  }  `}
                  aria-current="page"
                >
                  Music 
                </Link>
              </li>
              <li className="nav-item nav-font  ">
                <Link
                data="play-to-win"
                  to={{ pathname:"/play-to-win",state:{activeclass:'playToWin'}}}
                  onClick={() => {
                    setactiveClass("/play-to-win");
                  }}
                  className={`nav-link ${
                    activeClass === "/play-to-win" ? "active" : ""
                  }  `}
                  aria-current="page"
                  target="_blank"
                >
                  Play to win 
                </Link>
              </li>

              <li className="nav-item nav-font">
                <Link
                  to="/Nfts"
                  onClick={() => {
                    setactiveClass("/Nfts");
                  }}
                  className={`nav-link ${
                    activeClass === "/Nfts" ? "active" : ""
                  }  `}
                  aria-current="page"
                >
                  NFT's 
                </Link>
              </li>
              <li className="nav-item nav-font">
               <a href="https://whitepaper.polarverse.org/" target="_blank" className="nav-link">Whitepaper</a>
              </li>
              <li className="nav-item nav-font">
               <a href="https://whitepaper.polarverse.org/roadmap" target="_blank" className="nav-link">Roadmap</a>
              </li>
              <li className="nav-item nav-font">
                <Link
                  to="contact-us"
                  onClick={() => {
                    setactiveClass("/contact-us");
                  }}
                  className={`nav-link ${
                    activeClass === "/contact-us" ? "active" : ""
                  }`}
                >
                  Contact Us 
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
