import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { Link } from "react-router-dom";
import Contract from "../Contract";
import { useMetamask } from "use-metamask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3Eth from "web3-eth";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "../cssComponent/GamingNavbar.css";
export default function GamingNavbar(props) {
  
  const { connect, metaState } = useMetamask();
  const CurrentContract = Contract;
  const [playerBalance, setplayerBalance] = useState(null);
  const [activeClass, setactiveClass] = useState('playToWin');
  const [userAddresss, setuserAddresss] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [stackBalance, setStackBalance] = useState(null);

  useEffect(() => {
    getNetworkDetails();
    return () => {};
  }, [userAddresss, activeClass, errorMessage]);

  useEffect(() => {
    if (window.ethereum) {
      // detect Network account change
      window.ethereum.on("chainChanged", function (networkId) {
        getNetworkDetails();
      });
      window.ethereum.on("accountsChanged", function (accounts) {
        if (accounts[0]) {
          setuserAddresss(accounts[0]);
          getPlayerBalance();
          getStackBalance();
          getNetworkDetails();
        }
      });
    }
  }, [playerBalance, stackBalance]);

  useEffect(() => {
    getStackBalance();
    getPlayerBalance();
    return () => {};
  }, []);

  async function claimReward() {
    if (!playerBalance) {
      return alert("Not enough tokens to claim");
    }
    try {
      let result = await CurrentContract.claimTokenWinner();
      let confirm = await result.wait();
      if (confirm) {
        toast("Transaction Successfull");
        getPlayerBalance();
      }
    } catch (error) {}
  }

  async function ClaimStackToken() {
    if (!stackBalance) {
      return alert("Not enough tokens to claim");
    }
    try {
      let transactionHash = await Contract.claimStackTokens();
      let confirm = await transactionHash.wait();
      if (confirm) {
        toast("Transaction Successfull");
        getStackBalance();
      }
    } catch (error) {}
  }
  async function getStackBalance() {
    //player balance
    try {
      let web3 = new Web3(window.ethereum);
      let addresss = await web3.eth.getAccounts();
      if (window.ethereum) {
        setuserAddresss(addresss[0]);
        if (addresss.length > 0) {
          const balance = await CurrentContract.playerStackBalance(addresss[0]);
          if (balance) {
            let removeEighteenDigit = balance.toString();
            removeEighteenDigit = removeEighteenDigit.substring(
              0,
              removeEighteenDigit.length - 18
            );
            // setplayerBalance(removeEighteenDigit);
            setStackBalance(removeEighteenDigit);
          }
        }
      }
    } catch (error) {}
  }

  async function getPlayerBalance() {
    try {
      let web3 = new Web3(window.ethereum);
      let addresss = await web3.eth.getAccounts();
      if (window.ethereum) {
        setuserAddresss(addresss[0]);
        if (addresss.length > 0) {
          const balance = await CurrentContract.playerBalance(addresss[0]);
          if (balance) {
            let removeEighteenDigit = balance.toString();
            removeEighteenDigit = removeEighteenDigit.substring(
              0,
              removeEighteenDigit.length - 18
            );

            setplayerBalance(removeEighteenDigit);
          }
        }
      }
    } catch (error) {}
  }

  async function wallet() {
    if (metaState.isAvailable === false && metaState.isConnected === false) {
      return alert("Please install metamask first");
    }
    console.log("wineee", window.ethereum);

    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(handleAccountsChanged)
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log("Please connect to MetaMask.");
          } else {
            console.error(err);
          }
        });
    }
    function handleAccountsChanged(accounts) {
      let currentAccount;
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        console.log("Please connect to MetaMask.");
      } else if (accounts[0] !== currentAccount) {
        currentAccount = accounts[0];
        setuserAddresss(currentAccount);

        // Do any other work!
      }
    }


    getPlayerBalance();
  }

  async function disconnectMetamask() {
    if (window.ethereum) {
      setuserAddresss("");
    }
  }

  //check which network is connnected
  async function getNetworkDetails() {
    let eth;
    let netID;
    if (window.ethereum) {
      eth = new Web3Eth(window.ethereum);
      let network = 0;
      network = await eth.net.getId();
      netID = network.toString();
      console.log("netid",netID)
      if (netID === "137") {
        setErrorMessage("");
        return true;
      } else {
        setErrorMessage("Please connect Polygon Network");
      }
    }
  }

  //add polygon network
  async function addNetwork(type, testMain) {
    if (metaState.isAvailable === false && metaState.isConnected === false) {
      return alert("Please install metamask first");
    }
    let eth;
    let isTestnet = testMain;
    let netID;
    let web3 = new Web3();
    if (type === "web3") {
      if (typeof window.ethereum !== "undefined") {
        eth = new Web3Eth(window.ethereum);
      } else if (typeof web3 !== "undefined") {
        eth = new Web3Eth(web3.givenProvider);
      } else {
        eth = new Web3Eth(window.ethereum);
      }
    }

    if (typeof eth !== "undefined") {
      var network = 0;
      network = await eth.net.getId();
      netID = network.toString();
      var params;
      if (isTestnet === "false") {
        if (netID === "137") {
          alert("Polygon Network has already been added to Metamask.");
          return;
        } else {
          params = [
            {
              chainId: "0x89",
              chainName: "Matic Mainnet",
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              rpcUrls: ["https://polygon-rpc.com/"],
              blockExplorerUrls: ["https://polygonscan.com/"],
            },
          ];
        }
      } else {
        if (netID === "80001") {
          alert("Polygon Mumbai Network has already been added to Metamask.");
          return;
        } else {
          params = [
            {
              chainId: "0x13881",
              chainName: "Polygon Testnet",
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
              blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
            },
          ];

        }

      }

      window.ethereum
        .request({ method: "wallet_addEthereumChain", params })
        .then(() => {console.log("Success")
        setTimeout(() => {
          window.location.reload()
          
        }, 3000);
}        
        )
        .catch((error) => console.log("Error", error.message));
    } else {
      alert("Unable to locate a compatible web3 browser!");
    }
  }

  return (
    <div>
      {/* Gaming Navbar Section start */}
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <nav className="navbar navbar-expand-lg navbar-dark ">
          <div className="container-fluid">
            <Link className="navbar-brand logo" to="/home">
              <img src="./assets/polarwin-logo1.png"  width={100} height={60} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto text-right  font-roboto">
                {/* <li className="nav-item  ">
                  <Link
                    to="/home"
                    onClick={() => {
                      setactiveClass(
                        "home",
                        console.log("play", activeClass)
                      );
                    }}
                    className={`nav-link ${
                      activeClass === "home" ? "active" : ""
                    }  `}
                    aria-current="page"
                  >
                    Home {">"}
                  </Link>
                </li>  */}
                <li className="nav-item  ">
                  <Link
                    to="/play-to-win"
                    onClick={() => {
                      setactiveClass(
                        "playToWin",
                        console.log("play", activeClass)
                      );
                    }}
                    className={`nav-link ${
                      activeClass === "playToWin" ? "active" : ""
                    }  `}
                    aria-current="page"
                  >
                    Play To Win
                  </Link>
                </li>
                <li className="nav-item ">
                  <button className=" navButtonBorder">
                    <a
                      href="https://app.uniswap.org/#/swap"
                      rel="noopener noreferrer"
                      className="uniswapLink"
                      target="_blank"
                    >
                      {" "}
                      <abbr title="Get Polarwin tokens">1Pw = 1$ </abbr>{" "}
                    </a>{" "}
                  </button>
                </li>
                {/* <li className="nav-item ">
                  <button
                    className=" navButtonBorder claimButton  "
                    onClick={claimReward}
                  >
                    {playerBalance ? playerBalance : "00"}Pw Claim
                  </button>
                </li>
                <li className="nav-item ">
                  <button
                    className=" navButtonBorder  stackButton "
                    onClick={ClaimStackToken}
                  >
                    {stackBalance ? stackBalance : "0"}Pw Stake Claim
                  </button>
                </li> */}

                {/* <li className="nav-item ">
                  <div className="dropdown ">
                    <button
                      className="navButtonBorder  dropdown-toggle wallet"
                      onClick={wallet}
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {!userAddresss ? "Connect Wallet" : userAddresss}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <button
                          className="dropdown-item mb-0"
                          onClick={disconnectMetamask}
                        >
                          Disconnect
                        </button>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li className="nav-item">
                  <DropdownButton
                    variant="outline-secondary"
                    title="Connect"
                    className="navButtonBorder network"
                  >
                    <Dropdown.Item onClick={wallet} className="wallet">
                      {!userAddresss ? "Connect Wallet" : userAddresss}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={disconnectMetamask}>
                      Disconnect
                    </Dropdown.Item>
                    <Dropdown.Item onClick={claimReward}>
                      {playerBalance ? playerBalance : ""} Claim
                    </Dropdown.Item>
                    <Dropdown.Item onClick={ClaimStackToken}>
                      {stackBalance ? stackBalance : ""} Stake Claim
                    </Dropdown.Item>
                    {/* <Dropdown.Item href="#">Mainnet Network</Dropdown.Item> */}
                  </DropdownButton>
                </li>
                <li className="nav-item">
                  <DropdownButton
                    variant="outline-secondary"
                    title="Add Network"
                    className="navButtonBorder network"
                  >

                    <Dropdown.Item onClick={() => addNetwork("web3", "false")}>
                      Polygon Mainnet
                    </Dropdown.Item>
                  </DropdownButton>
                </li>
                <li className="nav-item">
                  <Link
                    to="history"
                    onClick={() => {
                      setactiveClass("history", activeClass);
                    }}
                    className={`nav-link  ${
                      activeClass === "history" ? "active" : ""
                    }`}
                  >
                    History
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="contact-us"
                    onClick={() => {
                      setactiveClass("contactus");
                    }}
                    className={`nav-link ${
                      activeClass === "contactus" ? "active" : ""
                    }`}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="errorMessage">
          {errorMessage === "Not enough token to claim..!" ? errorMessage : ""}
        </div>
        <div className="col-12 d-flex justify-content-center"></div>
        <div className="text-danger text-center">
          {errorMessage === "Please connect Polygon Network"
            ? "Please switch Polygon Network to play..!"
            : ""}
        </div>
        <hr className="bg-danger p-0 mt-0" />
      </div>
    </div>
  );
}
