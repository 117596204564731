import React from "react";
import WinnerSlider from "./WinnerSlider";
import Footer from "./Footer";
import ChartContainer from "./ChartContainer";
import GamingNavbar from "./GamingNavbar";
import { Link } from "react-router-dom";
export default function Home() {
  function scroll() {
    console.log("abc");
    console.log("window", window);
    window.scrollBy(0, 700);
  }
  return (
    <>
      {/* Gaming Navbar Section start */}
      <GamingNavbar />
      {/* Gaming Navbar Section end */}

      <div className="container-fluid  homeSection">
        <div className="row  homesection2">
          <div className="col-md-6 col-sm-12 d-block justify-content-center ">
            <h1 className="head1">
              Endless profit{" "}
              <span>
                {" "}
                <br />
              </span>{" "}
              For Everyone
            </h1>
            <ul className="headingPoints">
              <li>
                <span>Simplified Blockchain Binary Trading Platform</span>
              </li>
              <li>
                <span>Real-time crypto price predictions</span>
              </li>
              <li>
                <span>Live crypto Rating, News and Prices</span>
              </li>
              <li>
                <span>Daily Crypto Game - Win real Money</span>
              </li>
              <li>
                <span>Get your fund protected by insurance</span>
              </li>
              <li>
                <span>No registration - No Bank Account - No KYC required</span>
              </li>
              <li>
                <span>Completely Decentralized</span>
              </li>
            </ul>
            {/* <Link to="chart">
                    <button className="playButton " >Play Game</button>
                    
                    </Link> */}
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <img
              src="./assets/mockup1.png"
              className="img-fluid"
              alt="img"
            ></img>
          </div>
        </div>
        <div className="row pb-5 mb-5">
          <div className="col d-flex justify-content-center">
            <Link to="/game-rules" className="howItBtn ">
              {" "}
              How It Works
            </Link>
            <button className="letsPLaybtn" onClick={scroll}>
              Let's Play{" "}
            </button>
          </div>
        </div>
        <ChartContainer />
        <WinnerSlider />
        <Footer />
      </div>
    </>
  );
}
