import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import "./Gaming.css";
export default function Gaming(props) {
  return (
    <>
      <div
        className="container-fluid gameCss pb-5"
        style={{
          backgroundImage: "url('../assets/gameimg01.png')",
          height: "100vh",
          backgroundSize: "cover",
          // opacity: 0.9,
          backgroundRepeat: "no-repeat",
        }}
      >
        <Navbar data={props.location.pathname} />

        <div className="row gameRow">
          <div className="col-md-6  col-sm-12">
            <p className="gameHead">
              A New <br /> Perspective of Life <br /> with
              <span className="GamePolar"> POLARVERSE </span>
            </p>
            <button className="gamingButtoni" onClick={() => alert("upcoming")}>
              GAMING
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <h1 className="imminent">Launching this Year</h1>
          <div className="col-md-7 imminentDiv">
            <img
              src="../assets/gamegif.gif"
              style={{ marginTop: "2rem" }}
              alt="img"
              className="img-fluid"
            />
          </div>

          <div className="col-md-4">
            <p className="gameInfoText">
              Fist of Horror is a metaverse game that demands extreme skill. Not
              only do players use thier specialized powers to conquer the land,
              players will also need to use a variety of techniques and will
              need to strategize heavily in order to overcome their opponent!
              PolarVerse brings all this domination and conquest over the
              blockchain. The story of the game revolves around the player
              competing against other players in a 1 V 1 mode, or their team
              fighting other teams to conquer the pride and amenities.{" "}
            </p>
            <img src="../assets/gameremote.png" alt="img" />
          </div>
        </div>
      </div>
      <hr />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1 className="text-center advGame">Adventure Game</h1>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-7  col-sm-12 img-div-game">
            <img
              src="../assets/gameimg1.png"
              className="firstTopImg img-fluid"
              alt="img"
            />
            <img
              src="../assets/gameimg2.png"
              className="firstTopImg2 img-fluid"
              alt="img"
            />
            <img
              src="../assets/gameimg3.png"
              className="firstTopImg3 img-fluid"
              alt="img"
            />
          </div>
          <div className="col-md-5 d-flex justify-content-center">
            <div className="row">
              <h4 className="fw-bold pt-4 text-warning"> The Last Traveler </h4>
              <p className="gameContent1">
                1500 years ago, in Egypt the richest source of treasure on the
                planet. Kings in the valley the Great Pharaohs who were keeping
                their treasure inside the pyramid have stopped creating those
                and chose secluded cliffs to become their cemetery. Today,
                treasure hunter from all over the world come to unearthed
                millions worth treasure hidden in this “City of The Dead”. Deep
                inside the mountains amid a maze if tunnels that bore deep
                through the rock you can find the hidden tomb where you can find
                the treasure unlike anything ever seen before. Over more than
                5000 artifacts and Gold statues are inside various tombs and
                hidden at some of the secret places.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {/* <div className="container-fluid game3section">
        <div className="row">
          <div className="col">
            <h1 className="game3sectiontext">never try</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 gameTextinfdiv">
            <p className="gameTextinf">
              Our contracts are deployed on the Polygon Network. Hence, a player
              will need to have a nominal amount of Matic to pay for
              transactional gas fees. They will need to have a wallet (i.e.
              Metamask) that can store the Matic/WETH cryptocurrency and which
              can also store the PVER token. The player will need to set up
              their Metamask wallet to the Polygon Network.{" "}
            </p>
          </div>
          <div className="col-md-7 d-flex justify-content-center">
            <h1 className="game3sectiontext2">never know</h1>
          </div>
        </div>
      </div> */}
      <div className="container-fluid gamePreContainer">
        <div className="row">
          <div className="col-md-7 ">
            <div className="row">
              <div className="col-md-12 zealGif d-flex justify-content-center">
                <p className="gamePrerequisitesg ">Game</p>
                <img src="../assets/ZEAl_GIF.gif" className="" alt="gif" />
              </div>
              <div className="gamePrerequisites text-center">Prerequisites</div>
            </div>
          </div>
          <div className="col-md-5 gameContainer2">
            <p className="text-justify">
              Our contracts are deployed on the Polygon Network. Hence, a player
              will need to have a nominal amount of Matic to pay for
              transactional gas fees. They will need to have a wallet (i.e.
              Metamask) that can store the Matic/WETH cryptocurrency and which
              can also store the PVER token. The player will need to set up
              their Metamask wallet to the Polygon Network. The game runs
              through your browser, so most users will easily be able to enjoy a
              smooth gaming experience!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
