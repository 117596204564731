import React from "react";
import { Link, NavLink } from "react-router-dom";
// import LandingPage from './LandingPage';
import "./LandingPage.css";
import Footer from "../Footer";
// import Roadmap from "../Roadmap";
import Roadmap from "../Roadmap/Roadmap";
import BackgroundVideo from "./BackgroundVideo";
import Metaverse from "../Metaverse/Metaverse";
import Team from "../Pages/Team";
import HowToPlayVideo from "../YoutubeVideos/HowToPlayVideo";
export default function LandingPage() {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <BackgroundVideo />

        {/* //landingPageDiv1 */}
        <div
          className="container-fluid pt-5 landingPageContainer2"
          style={{
            backgroundImage: `url(./assets/effect.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "auto",
          }}
        >
          <div className="row">
            <h4 className="landingPageTextHeading pb-md-5 animate-charcter">
              POLARVERSE
            </h4>

            <div className="col-md-6 pt-5 text-center  col-sm-12 col-lg-12 col-xl-6 col-xxl-6">
              <h2 className="fw-bold text-uppercase ">
                PLay, Win, Earn, Repeat
              </h2>
              <p className=" landingPageText1">
                Polarverse is a platform that offers you an enormous opportunity
                to profit directly from your favorite metaverse games alongside
                the token's market prices! The Polarverse platform is currently
                built on Polygon, but the team is working hard to expand the
                project to other networks as we grow!
              </p>
            </div>
            <div className="col-md-6 py-md-4 pb-3  d-flex justify-content-center">
              <img src="../assets/welcomeVideo.gif" className="rounded" width={400} alt="collage" />
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <Link to="/gaming" className="button-85 text-decoration-none">
                Explore Game
              </Link>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-5 px-md-5">
              <h2 className="text-start landingPageContainer3Heading">
                Predict, Earn Crypto
              </h2>
              <ul>
                <li>Buy PWIN token</li>
                <li>Observe the market price</li>
                <li>Hit the predict buttons</li>
                <li>Earn reward in crypto</li>
                <li>Get insured</li>
                <li>Play more, earn more</li>
              </ul>
            </div>
            <div className="col-md-7">
              <img src="../assets/" alt="" />
            </div>
          </div> */}
        </div>

        <div
          className="container landingPageContainer3"
          style={{
            backgroundImage: `url(./assets/effect.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "auto",
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <HowToPlayVideo embedId="SojSL2djhCk" />
            </div>
            <div className="col-md-6 landingPageCol3">
              <h2 className="text-start landingPageContainer3Heading">
                Predict, Earn Crypto
              </h2>
              <ol className="text-start landingPageContainer3List">
                <li>Buy PWIN token</li>
                <li>Observe the market price</li>
                <li>Hit the predict buttons</li>
                <li>Earn reward in crypto</li>
                <li>Get insured</li>
                <li>Play more, earn more</li>
              </ol>
            </div>
          </div>
        </div>
        {/* container4  */}
        <div
          className="container-fluid   container4"
          style={{
            backgroundImage: `url(./assets/bg2.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "auto",
          }}
        >
          <div className="row ">
            <div className="col-md-4 col-sm-12  container4Col d-flex justify-content-center">
              <div className="col-md-8 container4Sec">
                <div className="col-md-12 d-flex justify-content-center">
                  <img
                    src="../assets/giphy1.gif"
                    className="container4img"
                    alt=""
                  />
                </div>
                <p className="text-center textShadow">Gaming</p>

                <div className="col d-flex justify-content-center">
                  <button
                    className="container4btn"
                    onClick={() => alert("upcoming")}
                  >
                    Upcoming
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12  container4Col d-flex justify-content-center">
              <div className="col-md-8 container4Sec">
                <div className="col-md-12 d-flex justify-content-center">
                  <img
                    src="../assets/giphy3.gif"
                    className="container4img"
                    alt=""
                  />
                </div>
                <p className="text-center textShadow">Predict & Earn crypto</p>

                <div className="col d-flex justify-content-center">
                  <Link
                    className="text-center container4btn "
                    to="/play-to-win"
                  >
                    {" "}
                    Play Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12  container4Col d-flex justify-content-center">
              <div className="col-md-8 container4Sec">
                <div className="col-md-12 d-flex justify-content-center">
                  <img
                    src="../assets/giphy2.gif"
                    className="container4img"
                    alt=""
                  />
                </div>
                <p className="textShadow text-center">polarverse world</p>
                <div className="col d-flex justify-content-center">
                  <button
                    className="container4btn"
                    onClick={() => alert("upcoming")}
                  >
                    Upcoming
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* container 5  */}
        <div className="container-fluid landingPageContainer5">
          <div className="row">
            <div
              className="col landingPageContainer5Heading"
              style={{
                backgroundColor: ` linear-gradient(180deg, #302284 0%, #140638 100%)`,
              }}
            >
              <h1 className="heading5 text-center">
                THE WORLD’S BIGGEST BLOCKCHAIN
                <br /> MUSIC PLATFORM ON POLARVERSE
              </h1>
            </div>
          </div>
        </div>
        <div
          className="container-fluid landingPageContainer6 "
          style={{
            backgroundImage: `url(../assets/landingpage6img.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <h1 className="landingPage6Heading">
                GET YOUR <br />
                MUSIC ON <br />
                POLARVERSE
              </h1>
            </div>
            <div className="col-md-12 col-sm-12  d-flex justify-content-center">
              <p>
              Polarverse provides a NFT Music platform for everyone to use! Integrate your music with NFTs in order to make a exclusive music & experiences for your top fans! Grow your fanbase and raise funds to support your career!
Convert your music to NFTs and list them for sale on our marketplace with just a few clicks!
              </p>
            </div>
            <div className="col-md-12 d-flex justify-content-center">
              <Link to="music">
                <button>Explore Now</button>
              </Link>
            </div>
          </div>
        </div>
        {/* //video */}

        {/* video end  */}

        <div className="container-fluid m-0 p-0">{/* <Metaverse /> */}</div>
        {/* <Roadmap /> */}
        <Team />
      </div>
      <Footer />
    </>
  );
}
