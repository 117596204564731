import { ethers } from "ethers";
import Contract from "./artifacts/contracts/GameV1.sol/GameV1.json";

if (window.ethereum) {
  const gameAddress = "0xc7f766f464d07aeebb69a10fdaaae7707fedc2e6";
 
  // const tokenAddress = "0x517416E57aB2ea3a9f388793d9678F86FAcF149E";
  const contractAbi = Contract.abi;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  var web = new ethers.Contract(gameAddress, contractAbi, signer);
}
export default web;
