import { ethers } from "ethers";
import Contract from "./artifacts/contracts/PolarWin.sol/PolarWin.json";

if (window.ethereum) {
  // const gameAddress = "0x6d38Af3C06Fa6e9EaD364a3d0f93996335Dfd5de";
  const tokenAddress = "0x6fe4e1d343353c8d0bafa52b9ac2a9249e02fe4a";
  const contractAbi = Contract.abi;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  var web = new ethers.Contract(tokenAddress, contractAbi, signer);
  
}
export default web;
