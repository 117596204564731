import React, { useCallback, useEffect, useState } from "react";
import Contract from "../Contract";
import { IsWeb3 } from "../utils";
import GamingNavbar from "./GamingNavbar";
export default function UserStakingHistory(props) {
  const [activeBetHistory, setActiveBetHistory] = useState([]);
  const [activeStakingHistoryArray, setactiveStakingHistoryArray] = useState(
    []
  );
  const [spinner, setspinner] = useState(false);
  const [toggle, settoggle] = useState("active");
  const [tradeToggle, setTradeToggle] = useState(true);
  useEffect(() => {
    setData();
    return () => {
      setActiveBetHistory([]);
      setactiveStakingHistoryArray([]);
    };
  }, []);

  const setData = useCallback(async () => {
    let userAddress = await IsWeb3();
    if (userAddress) {
      activeStakingHistory();
      setActiveBet();
    }
  }, []);

  // async function setData() {
  //     let userAddress = await IsWeb3();
  //     if (userAddress) {
  //         activeStakingHistory();
  //         setActiveBet()
  //     }

  // }

  //active bet history
  async function setActiveBet() {
    try {
      settoggle("active");
      setspinner(true);
      const address = await IsWeb3();
      let activeBetsFunction = await Contract.returnActiveBetReference();
      let activeBetArray;
      let activePrediciton = [];
      for (let x = 0; x < activeBetsFunction.length; x++) {
        if (
          address[0].toLowerCase() === activeBetsFunction[x][0].toLowerCase()
        ) {
          activeBetArray = await Contract.prediction(
            address[0],
            activeBetsFunction[x].ID.toNumber()
          );
          activePrediciton.push(activeBetArray);
        }
      }
      activePrediciton.reverse();

      setActiveBetHistory(activePrediciton);
      setspinner(false);
    } catch (error) {
      console.log(error);
      setspinner(false);
    }
    setspinner(false);
  }
  // set active end bet history
  async function setEndedBet() {
    try {
      settoggle("closed");
      setspinner(true);
      const address = await IsWeb3();
      let endBetsFunction = await Contract.returnEndedBetReference();
      let endBetArray;
      let endedPrediciton = [];

      for (let x = 0; x < endBetsFunction.length; x++) {
        if (address[0].toLowerCase() === endBetsFunction[x][0].toLowerCase()) {
          endBetArray = await Contract.prediction(
            address[0],
            endBetsFunction[x].ID.toNumber()
          );
          endedPrediciton.push(endBetArray);
        }
      }
      endedPrediciton.reverse();
      setActiveBetHistory(endedPrediciton);
      setspinner(false);
    } catch (error) {
      console.log(error);
      setspinner(false);
    }

    setspinner(false);
  }

  //set active stacking history
  async function activeStakingHistory() {
    try {
      settoggle("active");
      setspinner(true);

      const address = await IsWeb3();

      let activestackFunction = await Contract.returnActiveStackBetreference();
      let activestackarray;
      let activeStack = [];
      for (let x = 0; x < activestackFunction.length; x++) {
        if (
          address[0].toLowerCase() === activestackFunction[x][0].toLowerCase()
        ) {
          activestackarray = await Contract.lockStaking(
            address[0],
            activestackFunction[x].ID.toNumber()
          );
          activeStack.push(activestackarray);
        }
      }
      activeStack.reverse();
      setactiveStakingHistoryArray(activeStack);
      setspinner(false);
    } catch (error) {
      console.log( error);
      setspinner(false);
    }
    setspinner(false);
  }
  async function endedStakingHistory() {
    try {
      settoggle("closed");
      setspinner(true);

      const address = await IsWeb3();

      let endedstackFunction = await Contract.returnEndedStackBetreference();
      let endedstackarray;
      let endedStack = [];
      for (let x = 0; x < endedstackFunction.length; x++) {
        if (
          address[0].toLowerCase() === endedstackFunction[x][0].toLowerCase()
        ) {
          endedstackarray = await Contract.lockStaking(
            address[0],
            endedstackFunction[x].ID.toNumber()
          );
          endedStack.push(endedstackarray);
        }
      }
      endedStack.reverse();
      setactiveStakingHistoryArray(endedStack);
      setspinner(false);
    } catch (error) {
      console.log(error);
      setspinner(false);
    }
    setspinner(false);
  }

  function spiltCurrencyPrice(val) {
    // let ab = val.toString().slice(0, 6);
    // let bc = parseInt(ab);
    // let cd = bc.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67;
    return val;
  }

  function setTimeByCountry(val) {
    let myDate = new Date(val * 1000);
    return myDate.toLocaleString();
  }
  function removeEighteenDigit(val) {
    let digit = val.substring(0, val.length - 18);
    return digit;
  }

  return (
    <>
      <GamingNavbar data={props.location.pathname} />
      <div className="container my-4">
        {tradeToggle === true ? (
          <div className="container">
            <div className="row mb-5">
              <div className="col col-md-2">
                <p
                  className={`py-2 mx-2 h6 border  rounded text-center cursorPointer ${
                    tradeToggle === true ? "bg-light text-dark" : ""
                  }`}
                  onClick={() => {
                    setTradeToggle(true);
                  }}
                >
                  {" "}
                  TRADES{" "}
                </p>
              </div>
              <div className="col col-md-2">
                <p
                  className={`py-2 mx-4 h6 border rounded text-center cursorPointer ${
                    tradeToggle === false ? "bg-light text-dark" : ""
                  }`}
                  onClick={() => {
                    setTradeToggle(false);
                    settoggle("active");
                  }}
                >
                  {" "}
                  STAKE{" "}
                </p>
              </div>
            </div>
            {/* <h2 className="pb-2" onClick={()=>{setTradeToggle(false)}}>  TRADES </h2>
            <h2 className="pb-2" onClick={()=>{setTradeToggle(false)}}>  stake </h2> */}
            <div className="row d-flex justify-content-start">
              <div className="col col-sm-1 col-md-1">
                <p
                  className={`  ${
                    toggle === "active" ? "border-bottom activeHistory p-1" : ""
                  }`}
                  onClick={setActiveBet}
                  type="button"
                >
                  ACTIVE
                </p>
              </div>
              <div className="col col-sm-1 col-md-1">
                <p
                  className={` ${
                    toggle === "closed" ? "border-bottom activeHistory p-1" : ""
                  }`}
                  onClick={setEndedBet}
                  type="button"
                >
                  CLOSED
                </p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col TableOverFlow table-responsive">
                <table className="table  text-white border tableCss ">
                  <thead>
                    <tr className="bg-c-black ">
                      <th scope="col">#</th>
                      <th scope="col">CURRENCY</th>
                      <th scope="col">TOKEN</th>
                      <th scope="col">START TIME</th>
                      <th scope="col">END TIME</th>
                      <th scope="col">OPENING PRICE</th>
                      <th scope="col">CLOSING PRICE</th>
                      <th scope="col">UP OR DOWN</th>
                      <th scope="col">ISINSURED</th>
                      <th scope="col">DURATION</th>
                      <th scope="col">STATUS</th>
                    </tr>

                    <>
                      {spinner === true ? (
                        <tr className="text-center ">
                          <td colSpan={12}>
                            <div
                              className="spinner-border"
                              role="status"
                              style={{ textAlign: "center" }}
                            >
                              <span className="sr-only"></span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  </thead>

                  <tbody className=" text-center">
                    {activeBetHistory.length ? (
                      activeBetHistory.map((item, index) => {
                        return (
                          <tr key={index} className="bg-c-black tableCss">
                            <th scope="row">{++index}</th>
                            <td>{item.currency}</td>
                            {/* <td>t</td> */}
                            <td>
                              {item.stackAmount !== undefined
                                ? removeEighteenDigit(
                                    item.stackAmount.toString()
                                  ) + "  PWIN"
                                : "-"}
                            </td>
                            <td>
                              {" "}
                              <small>
                                {" "}
                                {item.startTimeStamp !== undefined
                                  ? setTimeByCountry(
                                      item.startTimeStamp.toNumber()
                                    )
                                  : ""}
                              </small>{" "}
                            </td>
                            <td>
                              <small>
                                {" "}
                                {item.endTimeStamp !== undefined
                                  ? setTimeByCountry(
                                      item.endTimeStamp.toNumber()
                                    )
                                  : ""}
                              </small>{" "}
                            </td>
                            <td>
                              {item.currentCurrencyPrice !== undefined
                                ? spiltCurrencyPrice(
                                    item.currentCurrencyPrice.toString()
                                  )
                                : "-"}
                            </td>
                            <td>
                              {item.currentCurrencyPrice !== undefined
                                ? spiltCurrencyPrice(
                                    item.endingCurrencyPrice.toString()
                                  )
                                : "-"}
                            </td>

                            <td>
                              {item.upOrDown !== undefined ? (
                                item.upOrDown.toString() === "true" ? (
                                  <span>
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="#00FF00"
                                      className="bi bi-arrow-up-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                    </svg>
                                  </span>
                                ) : (
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="#FF0000"
                                      className="bi bi-arrow-down-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                    </svg>
                                  </span>
                                )
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              {item.insured !== undefined &&
                              item.insured.toString() === true
                                ? item.insured.toString()
                                : item.insured.toString()}
                            </td>
                            <td>
                              {item.duration !== undefined
                                ? item.duration.toNumber() + " min"
                                : "-"}
                            </td>
                            <td>
                              {item.playerStatus !== undefined
                                ? item.playerStatus
                                : "-"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center ">
                        <td colSpan={12}>No History</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row mb-5">
              <div className="col col-md-2">
                <p
                  className={`py-2 mx-4 h6 border rounded text-center cursorPointer ${
                    tradeToggle === true ? "bg-light text-dark" : ""
                  }`}
                  onClick={() => {
                    setTradeToggle(true);
                  }}
                >
                  {" "}
                  TRADES{" "}
                </p>
              </div>
              <div className="col col-md-2">
                <p
                  className={`py-2 mx-4 h6 border rounded text-center cursorPointer ${
                    tradeToggle === false ? "bg-light text-dark" : ""
                  }`}
                  onClick={() => {
                    setTradeToggle(false);
                  }}
                >
                  {" "}
                  STAKE{" "}
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-start">
              <div className="col col-sm-1 col-md-1">
                <p
                  className={`  ${
                    toggle === "active" ? "border-bottom activeHistory p-1" : ""
                  }`}
                  onClick={activeStakingHistory}
                  type="button"
                >
                  ACTIVE
                </p>
              </div>
              <div className="col-1 ">
                <p
                  className={`  ${
                    toggle === "closed" ? "border-bottom activeHistory p-1" : ""
                  }`}
                  onClick={endedStakingHistory}
                  type="button"
                >
                  CLOSED
                </p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col TableOverFlow table-responsive">
                <table className="table text-white border tableCss ">
                  <thead>
                    <tr className="bg-c-black ">
                      <th scope="col">#</th>
                      <th scope="col">STAKE AMOUNT</th>
                      <th scope="col">REWARD %</th>
                      <th scope="col">START STAKE TIME</th>
                      <th scope="col">END STAKE TIME</th>
                      <th scope="col">REWARD AMOUNT</th>
                    </tr>
                    <>
                      {spinner === true ? (
                        <tr className="text-center ">
                          <td colSpan={12}>
                            <div
                              className="spinner-border"
                              role="status"
                              style={{ textAlign: "center" }}
                            >
                              <span className="sr-only"></span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  </thead>

                  <tbody className="">
                    {activeStakingHistoryArray.length ? (
                      activeStakingHistoryArray.map((item, index) => {
                        return (
                          <tr key={index} className="bg-c-black tableCss">
                            <th scope="row">{++index}</th>
                            <td>
                              {item.stackAmount !== undefined
                                ? removeEighteenDigit(
                                    item.stackAmount.toString()
                                  ) + "  PWIN"
                                : "-"}
                            </td>
                            <td>
                              {item.rewardPercentage !== undefined
                                ? item.rewardPercentage.toNumber() + "%"
                                : "-"}
                            </td>
                            <td>
                              {item.startStackTime !== undefined
                                ? setTimeByCountry(
                                    item.startStackTime.toNumber()
                                  )
                                : ""}
                            </td>
                            <td>
                              {item.endedStackTime !== undefined
                                ? setTimeByCountry(
                                    item.endedStackTime.toNumber()
                                  )
                                : ""}
                            </td>
                            <td>
                              {item.totalRewardAmount !== undefined
                                ? removeEighteenDigit(
                                    item.totalRewardAmount.toString()
                                  )
                                : "--"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center ">
                        <td colSpan={12}>No History</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
