import React from "react";
import Navbar from "../Navbar";
import "./Music.css";
import Demo from "./Player";
import Footer from "../Footer";
export default function Music(props) {
  return (
    <>
      <div
        className="container-fluid musicCss"
        style={{
          backgroundImage: "url('../assets/musicBg2.png')",
          height: "100%",
          paddingBottom:'80px',
          backgroundSize: "cover",
          // opacity: 0.9,
          backgroundRepeat: "no-repeat",
        }}
      >
        <Demo />
        <Navbar data={props.location.pathname} />

        <div className="row MusicRow">
          <div className="col-md-6  col-sm-12">
            <p className="musicHead">
              Let the Music <br />
              speak!{" "}
            </p>
            <p className="musicHead2">
              <span className="ull"> Music NFT</span> Engine
            </p>
            <p className="musicInfo">
              {" "}
              With a few clicks, simply mint your Unique Music NFT's on our
              platform.
            </p>
            <button className="musicBtnn" onClick={() => alert("Upcoming")}>
              Upcoming
            </button>
          </div>

          <div className="col-md-6 col-sm-12 d-flex justify-content-center musicHeadPhone">
            <img
              src="../assets/musicHeadphone2.png"
              className="img-fluid musicHeadphoneImg"
              alt="head"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid music2Container ">
        <div className="row">
          <div className="row">
            <h3 className="text-center">
              <span className="ul3"> Polarverse Music </span>
            </h3>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-9 col-sm-12 ">
              <p className="MuicNftInfo">
                In 2021, NFT sales hit $25 billion yet it is just the begining
                of the new trend. On March 11 of same year, A digital artwork by
                an American artist, Mike Winkelmann, known as Beeple was sold
                for $69,346,250 It is a collage of 5,000 individual images,
                which were made one-per-day over 5000 days (2007 - 2021) This
                Mike's NFT sales is the first ever sale by a major auction house
                of a piece of art that does not exist in physical form.
              </p>
            </div>
          </div>
          <div className="row musicPoints d-flex justify-content">
            <div className="col-md-6 ">
              <p className="polarverseMusicPoint text-justify ">
                <ul>
                  {" "}
                  <li>
                    {" "}
                    Several rich Crypto investors want to invest in digital collectibles like music NFT.{" "}
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-6   text-end">
              <img
                src="../assets/listenmusic.png"
                className="img-fluid musicPic"
                alt="music"
              />
            </div>
          </div>
          <div className="row musicPoints d-flex justify-content">
            <div className="col-md-6 col-sm-12 text-start">
              <img
                src="../assets/animate.png"
                className="img-fluid musicPic"
                alt="music"
              />
            </div>
            <div className="col-md-6 ">
              <p className="polarverseMusicPoint text-justify ">
                <ul>
                  {" "}
                  <li>
                  Many experts expect NFTs to prove especially transformative in shaping the future of music. {" "}
                  </li>
                </ul>
              </p>
            </div>
          </div>

          {/* abc  */}
          <div className="row musicPoints d-flex justify-content">
            <div className="col-md-6 ">
              <p className="polarverseMusicPoint text-justify ">
                <ul>
                  {" "}
                  <li>
                    {" "}
                    One of the biggest opportunities for musicians in terms of being fairly compensated for their content relates to secondary, tertiary, and higher-order markets for NFTs.{" "}
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-6 text-end">
              <img
                src="../assets/animate2.png"
                className="img-fluid musicPic"
                alt="music"
              />
            </div>
          </div>
          <div className="row musicPoints d-flex justify-content">
            <div className="col-md-6 ">
              <img
                src="../assets/animate3.png"
                className="img-fluid musicPic"
                alt="music"
              />
            </div>
            <div className="col-md-6 ">
              <p className="polarverseMusicPoint text-justify ">
                <ul>
                  {" "}
                  <li>
                    {" "}
                    In the music world, an NFT could be defined as a rare collectible that is stored on a digital ledger.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-light joinCommunity">
          <div className="row">
            <div className="col-md-6 ">
              <img
                src="../assets/bandcomuunity.png"
                className="img-fluid"
                alt="band"
              />
            </div>
            <div className="col-md-6 ">
              <p className="joinCommunitytext">
                Join the Community <br />
                and Get the best NFT's <br />
                Collection
              </p>
              <div className="joinCommunityDivBtn ">
                <a href="https://t.me/polarverse_win">
                <button className="joinCommunityBtn">Join Community</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container talent">
          <div className="row d-flex justify-content-center">
            <div className="col-md-10">
              <p className="talentTextNew">
              NFTs market has soared in recent months as enthusiasts and various investors begin to find more value in NFTs and began to buy up items that exist online. The items can exist in images, videos, music and Gifs. 
In February 2021, a 10-second video clip named, crossroad, also by Beeple, was sold for $6.6 million on an NFT marketplace.
              </p>
            </div>
          </div>
          <div className="row talentImg">
            <div className="col-12 text-center">
              <img
                src="../assets/talent1.png"
                className="img-fluid musicPic"
                alt="talent"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6  text-center talent2img">
              <img
                src="../assets/talent2.png"
                className="img-fluid musicPic"
                alt="talent"
              />
            </div>
            <div className="col-6 talent3img">
              <img
                src="../assets/talent3.png"
                className="img-fluid musicPic"
                alt="talent"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <img
                src="../assets/talent4.png"
                className="img-fluid musicPic"
                alt=""
              />
            </div>
            <div className="col-3  talent5">
              <img
                src="../assets/talent5.png"
                className="img-fluid musicPic"
                alt=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="talentLastText">
                Millions of upcoming musicians and professional artists desire
                to take their career to the next level but are faced with
                financial limitations to push their music to the entertainment
                industry for sale. Now it is made easy. Simply go digital.
                Polarverse music NFT engine and marketplace is the ideal Place
                for you. On our Music NFT engine, you can easily turn your real
                music to NFTs and let investors buy them.
              </p>
            </div>
            <div className="col talent6Img">
              <img
                src="../assets/talent6.png"
                className="img-fluid musicPic"
                alt=""
              />
            </div>
            <p className="getStarted">GET STARTED ...!</p>
          </div>
        </div>
        <hr />
        <Footer />
      </div>{" "}
    </>
  );
}
