import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default function FAQ() {
  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="row mb-5 faqFamily">
          <div className="col">
            <h1 className="pb-3"><u> FAQ </u></h1>
            <h3 className="fw-bold">What is Polarverse ?</h3>
            <p className="faqP1">
              Polarverse is a giant Multigame & Multitasking platform built on
              Polygon network.
            </p>
            <hr />
            <h3 className="fw-bold">What is Polarwin ?</h3>
            <p className="faqP1">
              Polarwin is a Cryptocurrency Price prediction gamified platform
              that allows users to earn real money (PWIN Token) by expressing an
              opinion on price in a fair and transparent way. The system takes
              inspiration from legacy financial markets and gaming to appeal to
              a wide array of users - from retail looking to speculate and play
              the market to institutions who can use the products to hedge risk
              and capture edge.
              <br /> <br />
              Built by a team of crypto enthusiast developers with a combined
              development experience of 30+ years and leveraging the power of
              the Polygon blockchain, Polarwin allows players to predict and
              play the game along with some real time information about the
              crypto to the user and enhance the gaming experience.
            </p>
            {/* key vallue pairs  */}
            <h3 className="fw-bold py-3">Key values of Polarverse</h3>
            <hr />
            <ul className="faqP1">
              <li>
                Game with high performance and scalability over blockchain{" "}
              </li>
              <li>Transparent to the players</li>
              <li>Easy</li>
              <li>Secured gaming environment </li>
              <li>High availability</li>
              <li>High liquidity</li>
              <li>Leveraging the blockchain capabilities</li>
            </ul>

            <h3 className="fw-bold py-3">What is Insurance ?</h3>
            <hr />
            <p className="faqP1">
              To protect the players against the risk of losing their entire
              tokens. Polarwin game can be played with the Insured option.
            </p>
            <ul className="faqP1">
              <li>Cover your risks</li>
              <li>Get x% of your token back after some amount of time </li>
              <li>
                Token gets staked over the blockchain which can be redeemed
              </li>
              <li>One click to get insured</li>
              <li>Address of the staking contract</li>
            </ul>
            <hr />
            <h3 className="fw-bold">What makes Polarwin Unique ?</h3>
            <ul className="faqP1">
              <li>
              Real-time market price prediction
              </li>
              <li>Insurance</li>
              <li>Staking</li>
              <li>Completely decentralized</li>
              <li>Low chance of losing money</li>
              <li>High chance of earning.</li>
              <li>Play anytime</li>
              <li>Win-Win project design</li>
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
