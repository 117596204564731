import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";

export default function ApexChart(props) {
  // console.log("propsin chartaaaaa", props.data);
  let a = [];
  const [chartData, setChartData] = useState([]);
  const [newchartData, setnewChartData] = useState([]);
  const ref = useRef("");

  function convert(t) {
    const dt = new Date(t);
    const hr = dt.getUTCHours();
    const m = "0" + dt.getUTCMinutes();
    // console.log("hr", m.substring(-2));
    // console.log("ms", m.substr(-2));
    return hr + ":" + m.substr(-2);
  }
  useEffect(() => {
    try {
      setChartData();
      // props.data.map((a) => {
      //   console.log("a", a);
      // return  a[4];
      // })

      props.data.map((e, i) => {
        // console.log("e", e[0]);
        a.push({
          x: convert(e[0]),
          y: [e[1], e[2], e[3], e[4]],
        });
      });
      // console.log("check data", a);
      setChartData(a);
      // a = props.data.map((a) => a.close);
    } catch (error) {
      console.log("errr", error);
      a = [];
    }

    return () => {};
  }, [props]);
  // console.log("props", props);

  // let b = props.data.map((b) => b.time);
  // var date = new Date();
  const series = [
    {
      name: "high",
      data: chartData,
    },
  ];
  const options = {
      title: {
      text: "", //show some text on chart top
      color: "#fff",
      align:'center',
      style:{
        color:'white'
      }
    },
    scales:{
      y:{
        ticks:{
          color:'red'
        }
      }
    },
    yaxis: {
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
            colors: ["white"],
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        // formatter: (value) => { return val },
    },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: true,
        intersect: true,
        inverseOrder: true,
        custom: undefined,
        fillSeriesColor: true,
        theme: false,
        style: {
          fontSize: "12px",
          fontFamily: "Roboto",
          color:'white'
        },
        onDatasetHover: {
          highlightDataSeries: true,
        },
        x: {
          show: true,
          format: "dd MMM",
          // formatter: undefined,
        },
        y: {
          formatter: undefined,
          title: {
            formatter: (seriesName) => seriesName,
          },
        },
        z: {
          formatter: undefined,
          title: "Size: ",
        },
        marker: {
          show: true,
        },
        items: {
          display: "flex",
        },
        fixed: {
          enabled: false,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
      },
    },
    xaxis: {
      // datetimeFormatter:"hour",
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
            colors: "white",
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        // formatter: (value) => { return val },
    },
      axisTicks: {
        show: false,
        borderType: "solid",
      },
    
      title: {
        style: { color: "white" },
      },
    },
    chart: {
      type: "candlestick",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 100,
        animateGradually: {
          enabled: true,
          delay: 10,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: true,
      },
    },

    dataLabels: {
      background: {
        enabled: false,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      enabled: false,

      style: {
        colors: ["#000000"],
        fontSize: "11px",
      },
      offsetX: 30,
    },
    //chart area
    stroke: {
      show: true,
      curve: "smooth", //smooth,straight,stepline
      lineCap: "butt", // butt, square,round
      colors: ["green"],
      width: 2,
      dashArray: 0, //more value more space in area
    },
    colors: ["transparent"],
    // colors: ['#77B6EA', '#545454'],
    fill: {
      type: "gradient",
      pattern: {
        style: "vertical",
        width: 6,
        height: 6,
        strokeWidth: 2,
      },
      gradient: {
        shadeIntensity: 1,
        inverseColors: true,
        gradientToColors: ["#c6fac3"],
        opacityFrom: 1,
        opacityTo: 1,
        type: "vertical",
        stops: [5],
        colorStops: [
          [
            {
              offset: 0,
              color: "#c6fac3",
              opacity: 0.1,
            },
            {
              offset: 5,
              color: "#c6fac3",
              opacity: 0.3,
            },
            {
              offset: 40,
              color: "#c6fac3",
              opacity: 0.4,
            },
          ],
        ],
      },
    },
    grid: {
      borderColor: "#FFFFFF",
    },
    noData: {
      text: "Data is not loaded",
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: true,
      intersect: false,
      inverseOrder: true,
      custom: undefined,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: "bold",
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
      x: {
        show: true,
        format: "dd MMM",
        formatter: undefined,
      },
      y: {
        show: true,
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      z: {
        show: true,
        formatter: undefined,
        title: "Size: ",
      },
      marker: {
        show: true,
      },
      items: {
        display: "flex",
      },
      fixed: {
        enabled: true,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };
  const options2 = {
    chart: {
      type: "candlestick",
      height: 350,
    },
    title: {
      text: "bc",
      align: "right",
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  };
  return (
    <>
      {chartData.length > 0 ? (
        <div id="chart">
          {/* {console.log("chartdata", chartData)} */}
          <Chart
            options={options}
            series={series}
            type="candlestick"
            height={450}
          />
        </div>
      ) : (
        <Chart options={options} series={[]} type="candlestick" height={450} />
      )}
    </>
  );
}
