import React, { useRef, useEffect } from "react";
import Navbar from "../Navbar";
import "./video.css";
export default function BackgroundVideo() {
  const videoRef = useRef();
  useEffect(() => {
    videoRef.current.playbackRate = 0.9;
    return () => {};
  }, []);
  return (
    <>
      {/* <Navbar /> */}

      <div className="relative">
      <div className="row">
        <div className="col-md-12">
        <video ref={videoRef} autoPlay loop muted id="bg-video">
          <source src="../assets/zeal4_1.mp4" type="video/mp4" />
        </video>
        </div>
      </div>
        {/* <video ref={videoRef} autoPlay loop muted id="bg-video">
          <source src="../assets/zeal4_1.mp4" type="video/mp4" />
        </video> */}
        <div className="absolute">
          <Navbar />
          <div className="col">
            <span className="explore">
              <h1>
                Explore The digital world <br />
                with <span className="world polarverseText" > POLARVERSE</span>
              </h1>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
