import React from "react";
import Navbar from "../Navbar";
import "./NFTComponent.css";
import Footer from "../Footer";
export default function NFTComponent(props) {
  return (
    <>
      <Navbar data={props.location.pathname} />
      <div className="  nftContainer">
        <div className="row nftRow">
          <div className="col-md-6 nftcol1">
            <h1 className="nftH1">
              Let's Start <br /> Something Big <br />
              With Polarverse
            </h1>
            <p>
              Explore the 10,000 collection of Polarbang NFTs that live in
              Polygon blockchain.
            </p>
            {/* <div className="row">
              <div className="col-2">
                <img
                  src="../assets/nft1.png"
                  className="smallNft smallNft1"
                  alt="nft"
                />
              </div>
              <div className="col-2">
                <img src="../assets/nft2.png" className="smallNft" alt="nft" />
              </div>
              <div className="col-2">
                <img src="../assets/nft3.png" className="smallNft" alt="nft" />
              </div>
            </div> */}
            <div className="col nftexplorebtndiv">
              <button
                className="nftExploreBtn"
                onClick={() => {
                  alert("upcoming");
                }}
              >
                EXPLORE
              </button>
            </div>
          </div>
          <div className="col-md-6  nftdiv">
            <div className="relativee">
              <div className="absolutee1">
                <img
                  src="../assets/nft1.png"
                  className="nftCard1 img-fluid"
                  alt=""
                />
                <div className="col bg-dark text-center">
                  <p className="bg-dark text-center fw-bold ">Thanos Bear</p>
                  <button className="text-center btn-secondary p-1">
                    14.00 PWIN
                  </button>
                </div>
              </div>
              <div className="absolutee">
                <img
                  src="../assets/nft2.png"
                  className="nftCard2 img-fluid"
                  alt=""
                />
                <div className="col bg-dark text-center">
                  <p className="bg-dark text-center fw-bold ">Goku Bear</p>
                  <button className="text-center btn-secondary p-1">
                    18.00 PWIN
                  </button>
                </div>
              </div>
              <div className="absolutee3">
                <img
                  src="../assets/nft3.png"
                  className="nftCard3 img-fluid"
                  alt=""
                />
                <div className="col bg-dark text-center">
                  <p className="bg-dark text-center fw-bold ">Hulk Bear</p>
                  <button className="text-center btn-secondary p-1">
                    24.00 PWIN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container conatiner2">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1> Trending</h1>
          </div>
          <div className="col-md-6 col-sm-12 nftDesc">
            <p>
              There will be a total of 10,000 collectibles of PolarBang NFTs and
              holders will only have the access to P2E game. Different NFTs will
              have different functions based on their rarity and uniqueness.
              NFTs will also contribute to the roles, attributes and as well as
              give privileges to users of the Polarverse platform <br />
              10,000 Collectible Polarbang NFTs on the Polygon Blockchain. These
              collectibles also serve as your tickets to Polarverse Metaverse
              and games. There are 100 unique traits. Super Rares and Legendery.
            </p>
          </div>
        </div>
        {/* // NFT card section  */}
        <div className="row mt-4">
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <img
                className="card-img-top"
                src="../assets/nftCard1.jpg"
                className="img-fluid"
                alt="Card image cap"
              />
              <div className="card-body">
                <h5 className="card-title text-dark">GOOD MORNING</h5>
                <p className="card-text text-danger text-end">Coming Soon</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mt-5">
            <div className="card">
              <img
                className="card-img-top"
                src="../assets/nftCard2.jpg"
                className="img-fluid"
                alt="Card image cap"
              />
              <div className="card-body">
                <h5 className="card-title text-dark">SUPERMAN</h5>
                <p className="card-text text-danger text-end">Coming soon</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <img
                className="card-img-top"
                src="../assets/nftCard3.jpg"
                className="img-fluid"
                alt="Card image cap"
              />
              <div className="card-body">
                <h5 className="card-title text-dark">GOOD NIGHT</h5>
                <p className="card-text text-danger text-end">Coming soon</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        {/* types of nft section  */}
        <div className="row nftType">
          <div className="col-md-4 col-sm-12  nftTypeCol">
            <img
              src="../assets/nftTypeImage.png"
              className="img-fluid mx-auto d-block"
              alt="nftType"
              width={75}
            />
            <p className="text-center nftTypeName">HAND DRAWN ARTWORK</p>
            <p className="text-center">
              Unique NFT artwork. The team of illustrators designed each image
              so that each NFT is personalised
            </p>
          </div>
          <div className="col-md-4 col-sm-12 nftTypeCol">
            <img
              src="../assets/nftTypeImage.png"
              className="img-fluid mx-auto d-block"
              alt="nftType"
              width={75}
            />
            <p className="text-center nftTypeName">AIR DROP</p>

            <p className="text-center">
              Future Airdrop of POLARVERSE, POLARWIN Tokens for NFT holders
            </p>
          </div>
          <div className="col-md-4 col-sm-12 nftTypeCol">
            <img
              src="../assets/nftTypeImage.png"
              className="img-fluid mx-auto d-block"
              alt="nftType"
              width={75}
            />
            <p className="text-center nftTypeName">BOOST REWARDS</p>

            <p className="text-center">
              In the future, NFT holders will get more token-farming rewards.
              It’s the “boosting” option during the surfing period of the
              POLARWINToken
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row nftSpecificationss">
          <hr />
          <div className="col-md-4">
            <h3 className="  nftSpecifications text-center ">Nft specifications</h3>
            <p className="specify">
            Each PolarBang is unique and generated from 100 possible traits
            including expression, head, clothing, mouth, eyes and much more. All
            PolarBangs are Unique, but some are rarer than others. You might
            just be the lucky one to grab the Legendary.
            </p> </div>
          <div className="col-md-4">
            <h6 className="  nftSpecifications text-center">Wallet whitelisting</h6>
          
          <p className="specify">  To guarrantee your chance of minting some of these Unique PolarBang
            NFT, You must whitelist your wallet. Don't miss out on this. It's
            already getting popped!
         </p> </div>
          <div className="col-md-4 getWhiteListed">
            <button className="  whitelisted "onClick={()=>alert("Upcoming")}  >Get whitelisted</button>
          </div>
        </div>
      </div>
      {/* join the community section  */}
      <div className="container-fluid bg-dark">
        <div className="row">
          <div className="col">
            <div className="col-md-6"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid communityContainer">
        <div className="row">
          <div className="col-md-6">
            <img
              src="../assets/communityCard.png"
              className="img-fluid"
              alt="communitycard"
            />
          </div>

          <div className="col-md-6 communityContainerCol2">
            <div className="row">
              <p className="communityText">
                Join the community <br />
                and get the best NFT's <br />
                collection
              </p>
            </div>
            <div className="row">
              <div className="col-md-12">
              <a href="https://t.me/polarverse_win">
                <button
                  className="joinComunnityBtn"
                  onClick={() => {
                    
                  }}
                >
                  Join Community
                </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
