import React, { useState, useRef, useEffect } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import HowToPlayVideo from "./YoutubeVideos/HowToPlayVideo";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
export default function GameRule() {
  let [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };
  const moreText = useRef();
  const dots = useRef();
  const btnText = useRef();
  // const [assetText, setAssetText] = useState("Asset");
  // const [durarionText, setDurarionText] = useState("Duration");
  // const [stakeText, setStakeText] = useState("Stake");
  // const [payoutText, setPayoutText] = useState("Payout");

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  function myFunction() {
    console.log("btn", btnText.current.innerHTML);
    if (dots.current.style.display == "none") {
      dots.current.style.display = "block";
      btnText.current.innerHTML = "Read more";
      moreText.current.style.display = "none";
    } else {
      dots.current.style.display = "none";
      btnText.current.innerHTML = "Read less";
      moreText.current.style.display = "inline";
    }
  }
  return (
    <>
      <Navbar />
      <div className="container-fluid hiwCon">
        <div className="row d-flex justify-content-center">
          <div className="col-md-7 howitworksContainer">
            <h1 className="text-justify">How To Play Prediction Game?</h1>

            <HowToPlayVideo embedId="xNiWfoi56_c" />
            <div className="col">
              <p>
                We currently use a flow chart which interacts with top price
                tracking tools to fetch integrated crypto prices as shown on our
                platform.
              </p>
              <p>
                You may consider taking a little time to observe the price
                movement before hitting the green or red button.
              </p>
              <p>
                According to the Dow Theory, the market considers everything
                during its pricing. The current asset prices are a reflection of
                all existing, prior, and upcoming details of the asset.
              </p>
              <p>
                This means that a market analyst can focus on the price of a
                coin, rather than every single variable that moves the price of
                a coin.{" "}
              </p>
              <p>
                Crypto markets go up and down in particular patterns. Being able
                to recognize the patterns of the market makes it possible to
                predict market behavior.
              </p>
            </div>
          </div>

          <div className="col-md-5 howItWorks3 ">
            <img
              src="../assets/Ellipse50.png"
              className="img-fluid"
              alt="elli"
            />
            <div className="howItWorks3re">
              <h1>
                Get the best NFT's <br /> Collection
              </h1>
              <span className="communityHow">Join Our Community</span>
            </div>
            <img
              src="../assets/image65.png"
              alt="img"
              className="img-fluid howitworksIMg"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid hIW5">
        <h1 className="ps-md-5">How To Play, Play to win game?</h1>
        <div className="row">
          <div className="col  ">
            <Carousel
              responsive={responsive}
              showDots={true}
              focusOnSelect={true}
              // autoPlay={true}
              // infinite={true}
              keyBoardControl={true}
            >
              <div className="carouselMy">
                <span className="relativeCarousel">1</span>
                <span>
                  Connect your wallet by clicking “connect” at the top of your
                  dashboard.
                </span>
              </div>

              <div className="carouselMy">
                <span className="relativeCarousel">2</span>
                Choose from the list of “assets” Select the time frame from
                “duration”
              </div>
              <div className="carouselMy">
                <span className="relativeCarousel">3</span>
                Enter the amount of PWIN token you desire to play with in the
                “stake” box Choose between “Insured” and “Uninsured” option and
                observe the payout.
              </div>
              <div className="carouselMy">
                <span className="relativeCarousel">4</span>
                Hit the Green (UP) or Red (Down)button to call the trade. From
                your wallet pop-up, confirm the transaction to start the game.
              </div>
              <div className="carouselMy">
                <span className="relativeCarousel">5</span>
                Once the transaction is confirmed, a countdown will begin on
                your dashboard showing your game timer.
              </div>
              <div className="carouselMy">
                <span className="relativeCarousel">6</span>
                You can play multiple times following the same procedure while a
                trade is still active.
              </div>
            </Carousel>
          </div>
        </div>
      </div>
      {windowWidth > 1024 ? (
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 col-sm-12 howToClaim d-flex justify-content-center pe-md-5">
              <h1 className="text-center cccclaim">How To Claim?</h1>

              <img
                src="../assets/Ellipse49.png"
                className="img-fluid"
                alt="ellipose"
              />
              <div className="claimRelative">
                <img src="../assets/des1.png" className="img-fluid" alt="des" />
              </div>
              <p className="claimRelative claimRelativetext">
                cick on the claim
              </p>
              <p className="claimRelative2 claimRelativetext2">
                WIN & CLAIM PWIN: If the result shows that you win, then your
                initial amount and the payout attached will be added to your
                “claim” vault located at the “Connect” drop-down menu. To claim
                your reward, click “Claim” and confirm the transaction from your
                wallet’s pop-up. Once the transaction is confirmed, check your
                wallet to see your PWIN tokens.
                <span id="dots" ref={dots}>
                  ...
                </span>
                <span id="more" ref={moreText}>
                  <br />
                  <br />
                  LOSE & STAKE PWIN : If the result shows that you lose and you
                  "insured" your trade before hitting the UP or DOWN button,
                  then your initial amount will be automatically sent to the
                  staking contract and will be locked up for XY days. When the
                  lock period is over, the PWIN token will be added to your
                  "stake" vault located at the "Connect" drop-down menu.To claim
                  your stake, click "Stake Claim" and confirm the transaction
                  from your wallet’s pop-up. Once the transaction is confirmed,
                  check your wallet to see your PWIN tokens.
                </span>
                <button ref={btnText} onClick={myFunction} id="myBtn">
                  Read more
                </button>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <h1 className="text-center py-4 my-4">How To Claim?</h1>

              <div className="col d-flex justify-content-center">
                <img src="../assets/des1.png" className="img-fluid" alt="des" />
              </div>
              <span className="claimBG text-center">cick on the claim</span>
            </div>
            <div className="ro">
              <div className="col d-flex justify-content-center">
              <p className=" claimRelativetext2 claimRelativetextres">
                WIN & CLAIM PWIN: If the result shows that you win, then your
                initial amount and the payout attached will be added to your
                “claim” vault located at the “Connect” drop-down menu. To claim
                your reward, click “Claim” and confirm the transaction from your
                wallet’s pop-up. Once the transaction is confirmed, check your
                wallet to see your PWIN tokens.
                <span id="dots" ref={dots}>
                  ...
                </span>
                <span id="more" ref={moreText}>
                  <br />
                  <br />
                  LOSE & STAKE PWIN : If the result shows that you lose and you
                  "insured" your trade before hitting the UP or DOWN button,
                  then your initial amount will be automatically sent to the
                  staking contract and will be locked up for XY days. When the
                  lock period is over, the PWIN token will be added to your
                  "stake" vault located at the "Connect" drop-down menu.To claim
                  your stake, click "Stake Claim" and confirm the transaction
                  from your wallet’s pop-up. Once the transaction is confirmed,
                  check your wallet to see your PWIN tokens.
                </span>
                <button ref={btnText} onClick={myFunction} id="myBtn">
                  Read more
                </button>
              </p>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="container">
        <div className="row ">
          <div className="col d-flex justify-content-center">
            <Link to="/play-to-win" className="howItBtn ">
              {" "}
              Let's Play
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

//   return (
//     <>
//       <Navbar />
//       <div className="container-fluid">
//         <h1 className="text-center py-3">Game Rule</h1>
//         <div className="row gap-3 px-5 gameRuleRow">
//           <div
//             className="col gameRuleAsset "
//             onMouseEnter={() => {
//               setAssetText("Select any crypto currency");
//             }}
//             onMouseLeave={() => {
//               setAssetText("Asset");
//             }}
//           >
//             <p>{assetText}</p>
//           </div>
//           <div
//             className="col gameRuleDuration"
//             onMouseEnter={() => {
//               setDurarionText("Select the duration/time");
//             }}
//             onMouseLeave={() => {
//               setDurarionText("Duration");
//             }}
//           >
//             <p>{durarionText}</p>
//           </div>
//           <div
//             className="col gameRuleStake"
//             onMouseEnter={() => {
//               setStakeText("Stake the PWIN token");
//             }}
//             onMouseLeave={() => {
//               setStakeText("Stake");
//             }}
//           >
//             <p>{stakeText}</p>
//           </div>
//           <div
//             className="col gameRulePayout"
//             onMouseEnter={() => {
//               setPayoutText("Enjoy the payout and earn profit");
//             }}
//             onMouseLeave={() => {
//               setPayoutText("Payout");
//             }}
//           >
//             <p>{payoutText}</p>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col">
//             <h2 className="text-center py-5">
//               {" "}
//               WATCH THE GRAPH GOES UP OR DOWN{" "}
//             </h2>
//             <p className="text-center  py-2 gameRuleText2 ">
//               We currently use a flow chart which interacts with top price
//               tracking tools to fetch integrated crypto prices as shown on our
//               platform. You may consider taking a little time to observe the
//               price movement before hitting the green or red button. According
//               to the Dow Theory, the market considers everything during its
//               pricing. The current asset prices are a reflection of all
//               existing, prior, and upcoming details of the asset. This means
//               that a market analyst can focus on the price of a coin, rather
//               than every single variable that moves the price of a coin. Crypto
//               markets go up and down in particular patterns. Being able to
//               recognize the patterns of the market makes it possible to predict
//               market behavior.
//             </p>
//           </div>
//         </div>
//         <div className="row predictTheGame text-center">
//           <div className="col-md-5 px-5">
//             <h1>
//               "Predict The <br /> Game
//             </h1>
//           </div>
//           <div className="col-md-2">
//             <p className="fw-bold h4 pb-4">Follow us</p>
//             <div className="col d-flex justify-content-between">
//               <span>
//                 <a
//                   href="https://github.com/polarverse"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <i className="fab fa-github"></i>
//                 </a>
//               </span>

//               <span>
//                 <a
//                   href="https://twitter.com/Polarverse_win"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <i className="fab fa-twitter"></i>
//                 </a>
//               </span>
//               <span>
//                 <a
//                   href="https://www.youtube.com/channel/UCrrZTVMQJ33wj99RwOMufLw/featured"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <i className="fab fa-youtube"></i>
//                 </a>
//               </span>

//               <span>
//                 <a
//                   href="https://medium.com/@polarverse"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <i className="fab fa-medium"></i>
//                 </a>
//               </span>

//               <span>
//                 <a
//                   href="https://t.me/polarverse_win"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <i className="fab fa-telegram"></i>
//                 </a>
//               </span>
//             </div>
//           </div>
//           <div className="col-md-5">
//             <h1>
//               Earn the <br /> profit"
//             </h1>
//           </div>
//         </div>
//         <div className="row mt-5">
//           <div className="col-md-12 d-flex justify-content-center">
//             <img src="../assets/gamerule.jpg" alt="abc" className="img-fluid" />
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-12">
//             <legend className="px-2 py-2 pb-3 fw-bold text-center h1">
//               {" "}
//               How it works
//             </legend>

//             {/* You can earn in multiple folds by predicting short-term
//                 movements of cryptocurrencies prices with just a few clicks.
//                 Simply choose a time frame and your investment (stake). Choose
//                 long (high) or short (low) positions depending on how you think
//                 the selected coin/ cryptocurrency will perform. Simple steps are
//                 to Enter the game, stake PWIN, and earn more PWIN. */}
//             <ul className="py-4 howitworks">
//               <li>
//                 Connect your wallet by clicking <span className="fw-bold"> “connect” </span> at the top of your
//                 dashboard.
//               </li>
//               <li> Choose from the list of <span className="fw-bold">“assets”</span> </li>
//               <li>Select the time frame from <span className="fw-bold"> “duration” </span></li>
//               <li>
//                 Enter the amount of PWIN token you desire to play with in the
//                 <span className="fw-bold"> “stake”</span> box
//               </li>
//               <li>
//                 Choose between <span className="fw-bold">“Insured” </span> and<span className="fw-bold"> “Uninsured” </span> option and observe the
//                 payout.
//               </li>
//               <li>
//                 Hit the <span className="fw-bold"> Green (UP)</span> or<span className="fw-bold"> Red (Down)</span>
//                  button to call the trade.
//               </li>
//               <li>
//                 From your wallet pop-up, confirm the transaction to start the
//                 game.
//               </li>
//               <li>
//                 Once the transaction is confirmed, a countdown will be begin on
//                 your dashboard showing your game timer.
//               </li>
//               <li>
//                 You can play multiple times following the same procedure while a
//                 trade is still active.
//               </li>
//               <li>
//                 When a trade/game session is completed, proceed to “History” on
//                 the playtowin dashboard to observe your trades or stakes
//               </li>
//               <li>
//                 From Trades, you can see your list of <span className="fw-bold"> “Active”</span> trades and
//                 <span className="fw-bold"> “Closed” </span> trades
//               </li>
//               <li>
//                 From Stake, you can see your list of <span className="fw-bold">  “Active”</span>  stakes and
//                 <span className="fw-bold">  “Closed” </span> stakes
//               </li>
//               <li>
//               <span className="fw-bold">  WIN & CLAIM PWIN: </span> If the result shows that you win, then your
//                 initial amount and the payout attached will be added to your
//                 “claim” vault located at the <span className="fw-bold">  “Connect” </span> drop-down menu. To claim
//                 your reward, click “Claim” and confirm the transaction from your
//                 wallet’s pop-up. Once the transaction is confirmed, check your
//                 wallet to see your PWIN tokens.
//               </li>
//               <li>
//               <span className="fw-bold">                 LOSE & STAKE PWIN: </span> If the result shows that you lose and you
//                 “insured” your trade before hitting the UP or Down button, then
//                 your initial amount will be automatically sent to the staking
//                 contract and will be locked up for xy days. When the lock period
//                 is over, the PWIN token will be added to your “stake” vault
//                 located at the <span className="fw-bold">  “Connect” </span> drop-down menu. To claim your stake,
//                 click “Stake Claim” and confirm the transaction from your
//                 wallet’s pop-up. Once the transaction is confirmed, check your
//                 wallet to see your PWIN tokens.
//               </li>
//             </ul>
//           </div>
//         </div>
//         <div className="row mt-5">
//           <div className="col-md-6 ">
//             <h3 className="text-center py-2">How to play</h3>
//             <HowToPlayVideo embedId="xNiWfoi56_c" />

//           </div>
//           <div className="col-md-6 py-2">
//             <h3 className="text-center">How to claim</h3>
//             <HowToPlayVideo embedId="leNd8XNVTc8" />

//           </div>
//         </div>
//         <div className="row">
//           <div className="col">
//             <Footer />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
