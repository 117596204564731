import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

export default function InsuranceCondition() {
    return (
        <>
        <Navbar />
            <div className="container mt-5">
                <div className="row">
                    <div className="col mt-5">
                        <h2>
                            Play With Insurance
                        </h2>
                        <ul className="headingPoints">
                            <li>
                                <span>Get your token covered even in case of loss.</span>
                            </li>
                            <li>
                                <span>You will be able to claim back X % of your token back with Y days.</span>
                            </li>
                            <li>
                                <span>Manage your risk in better manner.</span>
                            </li>

                        </ul>
                    </div>
                    <div className="col">
                        <img src="./assets/insurance-image.png" alt="img" className="img-fluid" />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
