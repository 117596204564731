import React from "react";
import "./team.css";
export default function Team() {
  return (
    <>
      <div className="container-fluid px-4 py-3">
        <div className="row py-5">
          <div className="col">
            <div class="waviy2">
              <span style={{ "--i": 1 }}>P</span>
              <span style={{ "--i": 2 }}>O</span>
              <span style={{ "--i": 3 }}>L</span>
              <span style={{ "--i": 4 }}>A</span>
              <span style={{ "--i": 5 }}>R</span>
              <span style={{ "--i": 6 }}>V</span>
              <span style={{ "--i": 7 }}>E</span>
              <span style={{ "--i": 8 }}>R</span>
              <span style={{ "--i": 9 }}>S</span>
              <span style={{ "--i": 10 }}>E</span>
              <span>_</span>

              <span style={{ "--i": 11 }}>T</span>
              <span style={{ "--i": 12 }}>E</span>
              <span style={{ "--i": 13 }}>A</span>
              <span style={{ "--i": 14 }}>M</span>
            </div>
          </div>
        </div>
        <div className="row   d-flex justify-content-center">
          <div className="col abcTeam">
            <div className="col-md-3 m-md-2 relative teambg">
              <div className="absolute teamImg">
                <img
                  src="../assets/team1.png"
                  className="img-fluid"
                  alt="zeal"
                />
                <h6 className="teamPos"> Founder & CEO</h6>
                <h6 className="teamName">Zeal</h6>
              </div>
            </div>
          </div>
          <div className="col abcTeam">
            <div className="col-md-3 m-md-2 teambg relative">
              <div className="absolute teamImg">
                <img
                  src="../assets/team2.png"
                  className="img-fluid"
                  alt="zeal"
                />
                <h6 className="teamPos"> PPA</h6>
                <h6 className="teamName">Thinkmedia</h6>

              </div>
            </div>
          </div>
          <div className="col abcTeam">
            <div className="col-md-3 m-md-2 teambg relative">
              <div className="absolute teamImg">
                <img
                  src="../assets/team3.png"
                  className="img-fluid"
                  alt="zeal"
                />
                <h6 className="teamPos"> COO</h6>
                <h6 className="teamName">Timothy</h6>

              </div>
            </div>
          </div>
          <div className="col abcTeam">
            <div className="col-md-3 m-md-2  teambg relative">
              <div className="absolute teamImg">
                <img
                  src="../assets/team4.png"
                  className="img-fluid"
                  alt="zeal"
                />
                <h6 className="teamPos"> Lead Developer</h6>
                <h6 className="teamName">Dharmendra</h6>

              </div>
            </div>
          </div>
          <div className="col abcTeam">
            <div className="col-md-3 m-md-2  teambg relative">
              <div className="absolute teamImg">
                <img
                  src="../assets/team5.png"
                  className="img-fluid"
                  alt="zeal"
                />
                <h6 className="teamPos"> Marketing Lead</h6>
                <h6 className="teamName">Ron</h6>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col">
            <div class="waviy my-5">
              <span style={{ "--i": 1 }}>P</span>
              <span style={{ "--i": 2 }}>A</span>
              <span style={{ "--i": 3 }}>R</span>
              <span style={{ "--i": 4 }}>T</span>
              <span style={{ "--i": 5 }}>N</span>
              <span style={{ "--i": 6 }}>E</span>
              <span style={{ "--i": 7 }}>R</span>
              <span style={{ "--i": 8 }}>S</span>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-12 d-flex justify-content-center">
            <img
              src="../assets/partners.png"
              className="img-fluid"
              alt="partners"
            />
          </div>
        </div>
      </div>
    </>
  );
}
