import Web3 from "web3"
export const IsWeb3 = async () => {
  let web3;

  if (window.ethereum ) {
    web3 =await new Web3(window.ethereum)
    
    let address = await web3.eth.getAccounts((error,result) => {
      if (error) {
          console.log(error);
      } if(result) {
          return result;
      }
  });
    return address;
  }

}


export const secToTime=(d)=>{
  if(d >0){

  
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);
  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay; 
  }
  else{

    return 0;
  }
  }